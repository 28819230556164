<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";

/**
 * Advanced-table component
 */

export default {
  page: {
    title: "Pending Orders",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, Multiselect, OrderReciept },
  data() {
    return {
      tableData: [],

      title: "Pending Orders",
      items: [
        {
          text: "View",
        },
        {
          text: "Pending Orders",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      daterange: [],
      isReportTableBusy:false,
      orderData: [],
      orderDetails: [],
      invoice: [],
      branches: [],
      branchID: "",
      restBranchID: this.$storageData.profile.restBranchID ? this.$storageData.profile.restBranchID : 0,
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      companyArr: [],
      companyID: 0,
      brandArr: [],
      brandData: "",

      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      orderStatusOptions: [
        { statusID: "2", statusName: "Pending" },
        { statusID: "3", statusName: "Accepted" },
        { statusID: "4", statusName: "Preparing" },
        { statusID: "5", statusName: "Prepared" },
        { statusID: "6", statusName: "Dispatched" },
        { statusID: "7", statusName: "Delivered" },
        { statusID: "8", statusName: "Cancelled" },
      ],
      orderStatus: "",
      fields: [
        // {
        //     key: "Action",
        //     sortable: false,
        // },
        {
          key: "orderID",
          sortable: true,
        },
        {
          key: "userName",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "restBranchName",
          sortable: true,
        },
        {
          key: "orderStatus",
          sortable: true,
        },
        {
          key: "totalSum",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "placedOn",
          sortable: true,
        },
        // {
        //   key: "deliveryDate",
        //   sortable: true,
        // },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    if (this.$storageData.profile.empTypeID == 1 || this.$storageData.profile.empTypeID == 9) {
      this.getAllCountry();
    } else if (
      this.$storageData.profile.empTypeID == 2 ||
      this.$storageData.profile.empTypeID == 11
    ) {
      this.getRestaurantCountry();
    } else if (this.$storageData.profile.empTypeID == 9) {
      this.getRestaurantBrands();
    }

    // this.getAllCountry();
    this.readPendingOrders();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "PendingOrder",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "pending-orders.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios.post(this.$loggedRole+"/getAllCountry").then((response) => {
        this.countryArr = response.data.data;
        // this.country = (this.countryArr) ? this.countryArr[0] : "";
        // this.getAllCities();
        if (
          this.$storageData.profile.empTypeID == 1 ||
          this.$storageData.profile.empTypeID == 9
        ) {
          // super admin
          // this.getRestaurantBrands();
          this.getAllCompanies();
        }
        if (this.$storageData.profile.empTypeID == 11) {
          // food company login
          this.getAllCompanies();
          this.getRestaurantBrandsByCompanyID();
        } else {
          this.readRestaurantBranchData();
        }
      });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
        })
        .then((response) => {
          this.cityArr = response.data.data;
          //this.city = (this.cityArr) ? this.cityArr[0] : "";
          if (this.$storageData.profile.empTypeID == 1) {
            // super admin
            // this.getRestaurantBrands();
            this.getAllCompanies();
          }
          if (this.$storageData.profile.empTypeID == 11) {
            // food company login
            this.getAllCompanies();
            this.getRestaurantBrandsByCompanyID();
          } else {
            this.readRestaurantBranchData();
          }
        });
    },
    getRestaurantBrands() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
          city: this.$storageData.profile.city,
        })
        .then((result) => {
          this.brandArr = result.data.data;
          // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantBrandsByCompanyID() {
      this.brandData = [];
      var companyID = this.companyID;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
          companyID: companyID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
        })
        .then((response) => {
          this.brandArr = response.data.data;
          // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", { restID: this.$storageData.profile.restID })
        .then((response) => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getAllCompanies();
          this.getRestaurantCities();
        });
    },
    getRestaurantCities() {
      if (this.$storageData.profile.city != "") {
        this.cityArr = [{ city: this.$storageData.profile.city }];
        this.city = this.cityArr ? this.cityArr[0] : "";
        this.getRestaurantBrands();
      } else {
        var restID = this.brandData.restID;
        if (this.$storageData.profile.empTypeID == 11) {
          restID = this.$storageData.profile.restID;
        }
        this.axios
          .post(this.$loggedRole+"/getRestaurantCities", {
            restID: restID,
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
            country: this.country ? this.country.country : "",
          })
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            if (this.$storageData.profile.empTypeID == 11) {
              this.getRestaurantBrandsByCompanyID();
            } else {
              this.readRestaurantBranchData();
            }
          });
      }
    },
    getAllCompanies() {
      this.companyArr = [];
      this.companyID = "";
      var companyID = 0;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getAllCompanies", {
          companyID: companyID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type,
        })
        .then((response) => {
          this.companyArr = response.data.data;
          if (this.$storageData.profile.empTypeID == 11) {
            this.companyID = this.companyArr ? this.companyArr[0] : "";
          }
          //  this.branchID = (this.branches) ? this.branches[0] :"";
          //  this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
          //  this.getProductMixReport();
        });
    },
    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          city: this.city ? this.city.city : "",
        })
        .then((response) => {
          this.branches = response.data.data;
          //  this.branchID = (this.branches) ? this.branches[0] :"";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          //  this.getProductMixReport();
        });
    },
    onChangeRestBrand() {
      this.readRestaurantBranchData();
      this.getRestaurantCities();
      //  if(this.restaurantID.restID>0){ /// for maintaining state of dropdown
      //    sessionStorage.setItem('restID',this.restaurantID.restID);
      //    sessionStorage.setItem('restName',this.restaurantID.name);
      // }
      // if(this.branchID.restBranchID>0){ /// for maintaining state of dropdown
      //    sessionStorage.setItem('branchID',this.branchID.restBranchID);
      //    sessionStorage.setItem('branchName',this.branchID.branchName);
      // }
    },
    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
      // this.getProductMixReport();
    },
    clearFilter() {
      this.daterange = [];
      this.brandData = "";
      this.restBranchID = "";
      this.country = "";
      this.companyID = "";
      this.brandData = "";
      this.city = "";
      this.branchID = "";
      this.readPendingOrders();
    },
    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.readPendingOrders();
    },

    // changes done by Mohammed on 13-12-2021 (added empTypeID and empID)
    readPendingOrders() {
      this.isReportTableBusy = true;

      if (this.orderStatus) {
        this.orderStatus = this.orderStatus.statusID;
      }
      this.axios
        .post(this.$loggedRole+"/viewAllPendingOrders", {
          restBranchID:
            this.$storageData.profile.empTypeID == 1 ||
            this.$storageData.profile.empTypeID == 2 ||
            this.$storageData.profile.empTypeID == 11
              ? this.restBranchID
              : this.$storageData.profile.restBranchID,
          dateFilter: this.daterange,
          orderStatus: this.orderStatus,
          restID:
            this.brandData != "" && this.brandData != null
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.isReportTableBusy = false;
          this.tableData = response.data.data;
          this.totalOrderAmount = response.data.totalOrderAmount;
          //console.log((response));
        });
    },

    dateFilter() {
      // alert(this.daterange);
      //this.readPendingOrders();
    },
    // applyFilter(){
    //     this.readPendingOrders();
    // },
    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
        .post(this.$loggedRole+"/getOrderDetailsByID", { orderID: orderID })
        .then((result) => {
          this.orderDetails = result.data.data;
          this.orderStatusArr = result.data.data.orderStatus;
          this.invoice = result.data.invoice;
          //  console.log(result.data.data.orderedItemArr);
          this.orderedItemArr = result.data.data.orderedItemArr;
          // this.orderItemAddonArr = result.data.orderItemAddonArr;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />

    <!--FILTER START------>
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div
            class="col-md-2"
            style="width: 11%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
              this.$storageData.profile.empTypeID == 9 ||
              this.$storageData.profile.empTypeID == 2 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              track-by="country"
              label="country"
              @input="getAllCompanies()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Country List END-->
          <!-- Company List-->
          <div
            class="col-md-2"
            style="width: 16%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
              this.$storageData.profile.empTypeID == 9 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Company</label>
            <multiselect
              v-model="companyID"
              :options="companyArr"
              track-by="companyID"
              label="companyName"
              @input="getRestaurantBrandsByCompanyID()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Compnay List END-->
          <!-- Brand List-->
          <div
            class="col-md-2"
            style="width: 16%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
              this.$storageData.profile.empTypeID == 9 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Restaurant Brand</label>
            <multiselect
              v-model="brandData"
              :options="brandArr"
              track-by="restID"
              label="name"
              @input="getRestaurantCities()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Brand List END-->
          <!-- Cities List-->
          <div
            class="col-md-2"
            style="width: 12%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
              this.$storageData.profile.empTypeID == 2 ||
              this.$storageData.profile.empTypeID == 9 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              track-by="city"
              label="city"
              @input="readRestaurantBranchData()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!--END Cities List-->
          <!-- Branch List-->
          <div
            class="col-md-2"
            style="width: 16%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
              this.$storageData.profile.empTypeID == 2 ||
              this.$storageData.profile.empTypeID == 9 ||
              this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Restaurant Branch </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              track-by="restBranchID"
              label="branchName"
              @input="onchangeBranch()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- Date range -->
          <div class="col-md-2" style="width: 20%">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              format="DD MMM Y"
              range
              append-to-body
              lang="en"
              confirm
              @input="onchangeBranch()"
            ></date-picker>
          </div>
          <!-- Date range END-->
          <!-- Apply filter -->
          <div class="col-md-2" style="width: auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->
          <!-- Clear filter -->
          <div class="col-md-2" style="width: 10%">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
          <!-- Order Type -->
          <!-- <div class="col-md-2"  style="width:18%">   
                            <label>Order Type </label>
                            <multiselect v-model="orderType" :options="orderTypeArr" @input="onchangeBranch();"></multiselect>
                </div> -->
          <!-- Order Type END-->

          <!-- Apply filter -->
          <!-- <div class="col-md-2"  style="width:auto">   
                 <button class="btn btn-themeOrange mt-4"  v-on:click="applyFilter()"> Apply Filter </button>
                </div> -->
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <!-- <div class="col-md-2"  style="width:10%">   
                 <button class="btn btn-themeBrown mt-4"  v-on:click="clearFilter()"> Clear Filter </button>
                </div> -->
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button
                    type="button"
                    class="btn btn-themeBrown"
                    @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      style="margin-left: 5px; margin-right: 5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped
                hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :busy="isReportTableBusy"
                foot-clone
              >
              <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
              </template>
                <!-- <template #cell(Action)>
                  <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                </template> -->
                <template v-slot:cell(orderID)="data">
                  <span
                    style="cursor: pointer; color: rgb(243, 118, 106);"
                    @click="viewOrderDetails(data.item.id)"
                    >{{ data.item.orderID }}</span
                  >
                </template>
                <template v-slot:cell(totalSum)="data">
                  <span>₹ {{ data.item.totalSum }}</span>
                </template>
                <template v-slot:foot(orderID)>
                  <span>Total:</span>
                </template>
                <template v-slot:foot(userName)>
                  <span></span>
                </template>
                <template v-slot:foot(restBranchName)>
                  <span></span>
                </template>
                <template v-slot:foot(orderStatus)>
                  <span></span>
                </template>
                <template v-slot:foot(totalSum)>
                  <span>{{ $func.formatPrice(totalOrderAmount) }}</span>
                </template>
                <template v-slot:foot(placedOn)>
                  <span></span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modals -->
    <OrderReciept
      :orderDetails="this.orderDetails"
      :orderedItemArr="this.orderedItemArr"
      :orderStatusArr="this.orderStatusArr"
      :invoice="this.invoice"
    >
    </OrderReciept>
  </Layout>
</template>

<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>